import { METALS_FIXING_MARKET_STATUS_UPDATE } from '../actions/action_types';

export default function metalsFixingMarketStatusesReducer(state = {}, action) {
  switch (action.type) {
    case METALS_FIXING_MARKET_STATUS_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
