export const OPENMODAL = 'open_modal';
export const UPDATEMODAL = 'update_modal';
export const HEADSUP_CHANGE = 'headsup_toggle';
export const USER_DETAILS = 'user_details';
export const USER_PERMISSIONS = 'user_permissions';
export const DISABLE_UI = 'disable_user_interface';
export const MODAL_MODIFY_ORDER = 'modal_modify_order';
export const MODAL_CLOSE = 'modal_close';
export const MODAL_SETTINGS = 'modal_settings';
export const MODAL_PASSWORD_CHANGE = 'modal_password_change';
export const SETTINGS = 'settings';
export const SYSTEM_STATUS = 'system_status';
export const SESSION_STATUS = 'session_status';
export const SESSION_SERVICE_STARTUP = 'session_service_startup';
export const LOAD_INSTRUMENT_PRICING_READY = 'load_instrument_pricing_ready';
export const LATENCY = 'latency';
export const INCOMING_ORDER = 'incoming_order';
export const UPDATE_ASSET = 'update_asset';
export const UPDATE_ASSET_RFQ = 'update_asset_rfq';
export const POST_ASSET = 'post_asset';
export const SET_STATS = 'set_stats';
export const SET_HISTORICAL_ORDERS = 'set_historical_orders';
export const ORDER_UPDATE = 'order_update';
export const SET_LAYOUT = 'set_layout';
export const SET_PERMISSIONS = 'set_permissions';
export const BOOK_UPDATE = 'book_update';
export const RFQ_UPDATE = 'rfq_update';
export const UPDATE_ASSET_PAIR_WIDGET = 'update_asset_pair_widget';
export const SET_SETTINGS = 'set_settings';
export const SEND_SETTINGS = 'send_settings';
export const REMOVE_SETTINGS = 'remove_settings';
export const SEND_INSTRUMENT_SETTINGS = 'send_instrument_settings';
export const PAUSE_TRADING = 'pause_trading';
export const RESUME_TRADING = 'resume_trading';
export const TOB_UPDATE = 'tob';
export const ADD_SUB = 'add_subscription';
export const REMOVE_SUB = 'remove_subscription';
export const START_RFQ_RFS_SUB = 'start_rfq_rfs_subscription';
export const START_RFQ_RFS_SUB_ALL = 'start_rfq_rfs_subscription_all';
export const REMOVE_RFQ_RFS_SUB = 'remove_rfq_rfs_subscription';
export const REMOVE_RFQ_RFS_SUB_ALL = 'remove_rfq_rfs_subscription_all';
export const UPDATE_SUB_ALGO = 'update_subscription';
export const FOLLOW_CLIENTS = 'follow_clients';
export const FOLLOW_THIS_CLIENT = 'follow_this_client';
export const CHANGE_FOLLOWER = 'change_follower';
export const FOLLOW_ORDER = 'follow_order';
export const CREDIT_CHECK_UPDATE = 'credit_check_update';
export const INSTRUMENTS_CREDIT_CHECK = 'instruments_credit_check';
export const SET_AUTHENTICATION_TOKEN = 'set_authentication_token';
export const SET_INITIAL_ORDERS = 'set_initial_orders';
export const IS_POPOUT = 'is_popout';
export const COMPLETED_ORDER = 'completed_order';
export const MATCHER_STATUS = 'matcher_status';
export const STP_UPDATE = 'stp_update';
export const CANCEL_ALL_ORDERS = 'cancel_all_orders';
export const SHADOW_FOLLOW = 'shadow_follow';
export const SET_RESTING_STATE = 'set_resting_state';
export const SET_INITIAL_STATE = 'set_all_initial_state';
export const ADD_MULTIPLE_SUBS = 'add_multiple_subs';
export const SET_CURRENT_DATE = 'set_current_date';
export const WEBSOCKET_MONITOR = 'websocket_monitor';
// export const BROWSER_PERF_CPU = 'browser_perf_cpu';
// export const BROWSER_PERF_RAM = 'browser_perf_ram';
export const ADD_RFQ_SUB = 'add_rfq_subscribe';
export const ADD_RFQ_SUB_LIST = 'add_rfq_subscription_list';
export const RFQ_DEAL_CLICKED = 'rfq_deal_clicked';

// ALLOCATIONS
export const SET_ACCOUNTS = 'set_accounts';

// metals fixing maket status
export const METALS_FIXING_MARKET_STATUS_UPDATE =
  'metals_fxing_market_status_update';
