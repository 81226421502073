import { createSelector } from 'reselect';

const orderModalSelector = createSelector(
  [
    (state) => state?.widgets_configuration?.order_types,
    (state) => state?.widgets_configuration?.orders_settings,
    (state) => state?.limitModal,
    (state) => state?.settings?.expire_time,
    (state) => state?.settings?.lang,
  ],
  (orderTypesAllowed, orders_settings, limitModal, expireTimeSecond, lang) => ({
    display: limitModal.modal,
    symbol: limitModal.symbol,
    instrumentId: limitModal.instrument_id,
    slippage: limitModal.slippage,
    orderTypeSelected: limitModal.orderTypeSelected || 'LIMIT',
    expireTimeSecond,
    maxAmountPerOrder: limitModal.max_amount_per_order,
    termCurrency: limitModal.term_currency,
    lang,
    ndf: limitModal.ndf,
    settleDate: limitModal.settle_date,
    fixingDate: limitModal.fixing_date,
    tenor: limitModal.tenor,
    orderTypesAllowed,
    manoffsetWarning:
      orders_settings?.manoffset_warning === undefined
        ? true
        : orders_settings?.manoffset_warning,
    allowIfDone: orders_settings?.allow_if_done || false,
    allowNote: orders_settings?.allow_client_order_text_note || false,
  })
);

export default orderModalSelector;
