import { useState, useEffect } from 'react';

const useDocumentVisibility = (track: boolean): boolean => {
  const [isDocumentVisible, setIsDocumentVisible] = useState(
    document.visibilityState === 'visible'
  );

  const handleVisibilityChange = () => {
    setIsDocumentVisible(document.visibilityState === 'visible');
  };

  useEffect(() => {
    if (track) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [track]);

  return isDocumentVisible;
};

export default useDocumentVisibility;
