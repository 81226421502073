import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { formatNumberToAbr } from '~utils/formatNumber';
import { NotificationManager } from '../common/Notifications';

import {
  audioBuy,
  audioSell,
  audioBuySpanish,
  audioSellSpanish,
} from '../../utils/sounds';
import dateTime from '../../utils/date-time';
import { ymd } from '../../configs/dateFormatStr';

const ENTRY_DATE = new Date().getTime();

export const validateToday = (orderDate) => {
  const currentDate = dateTime.getEtcDateFromUserTimeZone(new Date());
  const after5pm = dateTime.isAfterTime(new Date(), '17:00');
  const todayTradeDate = after5pm
    ? format(addDays(currentDate, 1), ymd)
    : format(currentDate, ymd);

  return Number(todayTradeDate) === Number(orderDate);
};

export const validateSessionTime = (timestamp) => {
  if (!timestamp) return true; // just continue if field doesn't exists
  const stamp = new Date(timestamp).getTime();
  if (Number.isNaN(stamp)) return true;

  return stamp > ENTRY_DATE;
};

const playSound = (opts = {}) => {
  if (!opts.sound) return;

  if (opts.lang === 'es') {
    switch (opts.side) {
      case 'BUY':
        audioBuySpanish.play().catch((err) => {
          console.warn(err);
        });
        break;
      case 'SELL':
        audioSellSpanish.play().catch((err) => {
          console.warn(err);
        });
        break;
      default:
        break;
    }
  } else {
    switch (opts.side) {
      case 'BUY':
        audioBuy.play().catch((err) => {
          console.warn(err);
        });
        break;
      case 'SELL':
        audioSell.play().catch((err) => {
          console.warn(err);
        });
        break;
      default:
        break;
    }
  }
};

/**
 *
 * @param {*} data
 * @param {*} type
 * @param {*} opts
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const createUserNotification = (data, type, opts) => {
  if (!opts.showNotification) {
    return null;
  }

  playSound(opts);

  switch (type) {
    case 'cancel':
      return NotificationManager.error(data, null, 10000);
    case 'partial':
      return NotificationManager.warning(data, null, 10000);
    case 'active':
      return NotificationManager.info(data, null, 10000);
    case 'complete':
      return NotificationManager.success(data, null, 10000);
    default:
      return null;
  }
};

export const userNotificationType = (status, qtyFilled) => {
  if (status === 'NEW') {
    return 'active';
  }

  if (
    status === 'PARTIALLY_FILLED' ||
    (status === 'CANCELED' && qtyFilled > 0)
  ) {
    return 'partial';
  }

  if (status === 'FILLED') {
    return 'complete';
  }

  return 'cancel';
};

export const userNotificationMessage = (data, status, lang, l) => {
  const msg = `${lang.status[status][l]}: ${lang.side[data.side][l]} ${
    data.symbol
  }`;

  if (Number(data.qty_open) === 0) {
    return `${msg} ${formatNumberToAbr(data.qty_filled)} ${data.currency} @ ${
      Number(data.fill_price_vwap) === 0
        ? lang.order_type.MARKET[l]
        : data.fill_price_vwap
    }`;
  }

  if (['NEW', 'PARTIALLY_FILLED'].includes(status)) {
    return `${msg} ${formatNumberToAbr(data.qty_original)} ${data.currency} @ ${
      ['MARKET', 'STOP_MARKET', 'FIXING'].includes(data.order_type)
        ? lang.order_type?.[data.order_type.replace(/STOP_/g, '')]?.[l]
        : data.price
    }`;
  }

  return '';
};

export const StpNotificationText = ({
  dataParent,
  order,
  stateOrderStop,
  orderLang,
  lang,
  isCurrentUser = true,
}) => {
  const { client_order_id: clientOrderId, side, qty_stp: qtyStp } = order;
  const { symbol, currency, fill_price_vwap: fillPriceVwap } = dataParent;

  const texts = [`${clientOrderId} - `];

  if (!isCurrentUser) {
    texts.push("<b class='titleNotifyFixed'>");
  }

  texts.push(orderLang.status[stateOrderStop][lang]);

  if (!isCurrentUser) {
    texts.push('</b>: ');
  } else {
    texts.push(' : ');
  }

  texts.push(
    `${orderLang.side[side][lang]} ${symbol} ${formatNumberToAbr(
      qtyStp
    )} ${currency} @ `
  );

  texts.push(
    Number(fillPriceVwap) === 0
      ? orderLang.order_type.MARKET[lang]
      : fillPriceVwap
  );

  return texts.join('');
};

export const ViewerNotificationText = ({ lang, data, orderLang }) => {
  const {
    currency,
    fill_price_vwap: fillPriceVwap,
    order_type: orderType,
    qty_original: qtyOriginal,
    side,
    symbol,
  } = data;

  const texts = [
    `<b class="titleNotifyFixed">${orderLang.side[side][lang]}</b>: `,
  ];
  const price = ['MARKET', 'STOP_MARKET', 'FIXING'].includes(orderType)
    ? orderLang.order_type?.[orderType.replace(/STOP_/, '')]?.[lang]
    : fillPriceVwap;

  texts.push(`${symbol} ${formatNumberToAbr(qtyOriginal)} ${currency} @ `);
  texts.push(`${price}`);

  return texts.join('');
};
